import { Store } from "@retailtune/types/lib/store";
import { Device } from "@retailtune/types/lib/device";
import { Position } from "@retailtune/types/lib/geolocation";
import {
  AnalyticsCategory,
  AnalyticsAction,
} from "@retailtune/types/lib/analytics";
import { Translations } from "../common/translations";
import {
  createAutocomplete,
  createScrollButton,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";
import {
  createAutocompleteHandler,
  retailTuneAutocompleteHandler,
  api,
  setExpirable,
  USER_POSITION_CONSENT,
  USER_POSITION,
  fetchUserDefaultPosition,
  fetchUserPosition,
  getExpirable,
  createExpirationValue,
  positionToLatLngLiteral,
  createDebounceFn,
  getDevice,
  createPosition,
  sortStoresByPriority,
  getRadialDistanceFn,
} from "@retailtune/utils";
import {
  googleAutocompleteHandler,
  CustomRenderer,
} from "@retailtune/google-maps-utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { style } from "../common/mapStyle";
import { createOpeningHoursText } from "../common/openingHours";

// ---------- Type definitions ----------

declare const retailtune: {
  api_key: string;
  urlPrefix: string;
  language: string;
  translations: Translations;
};

// Analytics
declare function sendGa4DL(
  category: AnalyticsCategory,
  action: AnalyticsAction,
  label: string,
  storeId: number
): void;

interface Positions {
  user: Position;
  default: Position;
  current: Position;
}

interface Directions {
  origin: Position;
  destination: Store | null;
  travelMode: google.maps.TravelMode;
}

// ---------- Global state ----------

let stores: Store[];
let lastSelectedStore: Store | null;
let visibleStores: Store[];
let storeIdToMarkerMap = new Map<number, google.maps.Marker>();
let storeCountriesSet = new Set<string>();

let clearAutocomplete: () => void;
let toast: (message: string) => void;

let positions: Positions;
let directions: Directions;
let travelModes: google.maps.TravelMode[];

const searchParams = new URLSearchParams(window.location.search);
let deviceType: Device;
let assetHostname: String;

let map: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;
let directionsResult: google.maps.DirectionsResult;
let markerClusterer: MarkerClusterer;
let destinationMarker: google.maps.Marker | null;

// ---------- main Function ----------

async function main() {
  // ---------- Set-ups ----------

  api.setOrigin(process.env.NODE_ENV as any);

  deviceType = getDevice();

  const { api_key, translations, language } = retailtune;

  const [hostnames, data, defaultPosition] = await Promise.all([
    api.fetchHostnames(api_key),
    api.fetchPageData(api_key, language),
    fetchUserDefaultPosition(api_key),
  ]);

  stores = data.stores;
  assetHostname = hostnames.assetHostname;

  // Position state setup
  const userPosition = getExpirable<Position>(USER_POSITION) ?? defaultPosition;
  positions = {
    current: userPosition,
    default: defaultPosition,
    user: userPosition,
  };

  const queryPositionPositionLatLng = searchParams.get("pos")?.split(",");
  if (queryPositionPositionLatLng) {
    positions.current = createPosition({
      latitude: +queryPositionPositionLatLng[0],
      longitude: +queryPositionPositionLatLng[1],
    });
  }

  for (let i = 0; i < stores.length; ++i) {
    if (stores[i].status === "closed") continue;

    // create store marker and add it to marker map
    const storePosition = { lat: stores[i].latitude, lng: stores[i].longitude };
    const marker = new google.maps.Marker({
      position: storePosition,
      icon: `${assetHostname}/img/pin/pin-store.svg`,
      map,
    });
    marker.addListener("click", () => {
      infoWindow.setContent(createInfoWindow(stores[i]));
      infoWindow.open(map, marker);

      lastSelectedStore = stores[i];
      google.maps.event.trigger(map, "bounds_changed");

      // Analytics
      sendGa4DL(
        "Store",
        "Click",
        `StoreClickMap-${stores[i].city} ${stores[i].address1}-${deviceType}-${language}`,
        stores[i].id
      );
    });
    storeIdToMarkerMap.set(stores[i].id, marker);

    // add to unique country set
    storeCountriesSet.add(stores[i].country.alpha2);
  }

  // Map state setup
  const mapContainerEl = document.getElementById("rt_map_container")!;
  map = new google.maps.Map(mapContainerEl, {
    zoom: 8,
    center: positionToLatLngLiteral(positions.current),
    styles: style,
  });

  let mapDebounce = createDebounceFn()(200);
  map.addListener("bounds_changed", () => {
    const mapBounds = map.getBounds()!;
    mapDebounce(() => {
      getStoreCards(mapBounds);

      deviceType = getDevice();
      if (deviceType === "desktop" && lastSelectedStore) {
        const selectedStoreCardEl = document.getElementById(
          `rt_${lastSelectedStore.id}`
        )!;
        selectedStoreCardEl.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        lastSelectedStore = null;
      }
    });
  });

  infoWindow = new google.maps.InfoWindow();

  userMarker = new google.maps.Marker({
    position: positionToLatLngLiteral(positions.current),
    icon: `${assetHostname}/img/pin/pin-user.svg`,
    map,
  });

  markerClusterer = new MarkerClusterer({
    map,
    markers: Array.from(storeIdToMarkerMap.values()),
    renderer: new CustomRenderer({
      clusterIcon: `${assetHostname}/img/pin/cluster.svg`,
    }),
  });

  // Directions state setup
  travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.TRANSIT,
  ];

  directions = {
    origin: positions.user,
    destination: null,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map,
    polylineOptions: {
      strokeOpacity: 1,
      strokeColor: "#000",
    },
    markerOptions: { visible: false },
  });

  // ---------- Miscellaneous DOM modifications ----------

  const modalCloseButt = document.getElementById("rt_modal_close_butt")!;
  modalCloseButt.onclick = () => showConsentModal(false);

  const findClosestStoreButt = document.getElementById(
    "rt_find_closest_store_butt"
  )!;
  findClosestStoreButt.onclick = () => {
    clearDrivingDirections();
    geolocateUser();

    // Analytics
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const backToNearestStoreButt = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  backToNearestStoreButt.addEventListener("click", () => {
    if (directions.destination) {
      navigateUser();
    }
    let mapZoom = map.getZoom();
    let mapBounds = map.getBounds();
    while (
      mapBounds &&
      mapZoom &&
      getStoreCards(mapBounds) === 0 &&
      mapZoom !== 0
    ) {
      map.setZoom(--mapZoom);
      mapBounds = map.getBounds()!;
    }
  });

  const directionsPaneCloseButt = document.getElementById(
    "rt_directions_pane_close_butt"
  )!;
  directionsPaneCloseButt.addEventListener("click", () =>
    clearDrivingDirections()
  );

  const positionConsentModalApprovalButt = document.getElementById(
    "rt_position_consent_modal_approval"
  )!;

  positionConsentModalApprovalButt.onclick = () => {
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });
    geolocateUser();
    showConsentModal(false);

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  const positionConsentModalRejectionButt = document.getElementById(
    "rt_position_consent_modal_rejection"
  )!;
  positionConsentModalRejectionButt.onclick = () => {
    showConsentModal(false);

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };

  window.onresize = () => {
    const newDeviceType = getDevice();
    if (
      directions.destination &&
      newDeviceType !== "desktop" &&
      deviceType === "desktop"
    ) {
      clearDrivingDirections();
    }
    deviceType = newDeviceType;
  };

  // ---------- Dynamic component rendering ----------

  [toast] = createToastMessagesContainer({
    anchor: "rt_map_list_container",
    position: "top-right",
  });

  // StoreList scrollToTop Button
  createScrollButton({
    anchorEl: "rt_list_container",
    scrollingEl: "rt_list_container",
    id: "rt_store_list_scroller",
  });

  // DirectionsPane scrollToTop Button
  createScrollButton({
    anchorEl: "rt_directions_pane_container",
    scrollingEl: "rt_directions_pane_container",
    id: "rt_directions_pane_scroller",
  });

  createAutocomplete({
    anchor: "rt_autocomplete_container",
    predictionClickHandler: (prediction) => {
      clearDrivingDirections();
      positions.current = createPosition({
        latitude: prediction.latitude,
        longitude: prediction.longitude,
      });
      const currentLatLngLiteralPosition = positionToLatLngLiteral(
        positions.current
      );
      map.setCenter(currentLatLngLiteralPosition);
      map.setZoom(8);
      userMarker.setPosition(currentLatLngLiteralPosition);

      // Analytics
      sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
    },
    searchHandler: createAutocompleteHandler(
      retailTuneAutocompleteHandler(api_key, {
        language,
        countries: Array.from(storeCountriesSet),
      }),
      googleAutocompleteHandler()
    ),
    zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    placeholder: translations.k_autocomplete_placeholder,
    searchIcon: {
      path: `${assetHostname}/img/icon/search.svg`,
      position: "right",
    },
  });

  [clearAutocomplete] = createAutocomplete({
    anchor: "rt_address_origin",
    predictionClickHandler: (prediction) => {
      positions.current = createPosition({
        latitude: prediction.latitude,
        longitude: prediction.longitude,
      });
      directions.origin = positions.current;
      navigateUser();

      // Analytics
      sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
    },
    searchHandler: createAutocompleteHandler(
      retailTuneAutocompleteHandler(api_key, {
        language,
        countries: Array.from(storeCountriesSet),
      }),
      googleAutocompleteHandler()
    ),
    zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    placeholder: translations.k_autocomplete_placeholder,
  });

  // ---------- Static component rendring ----------

  const travelModescontainer = document.getElementById("rt_travel_modes")!;
  for (let travelMode of travelModes) {
    const cls =
      "rt-travel-mode " +
      (travelMode === directions.travelMode ? "rt-travel-mode--selected" : "");
    travelModescontainer.appendChild(
      <>
        <button
          class={cls}
          travel-mode={travelMode.toLowerCase()}
          onclick={() => {
            directions.travelMode = travelMode;
            navigateUser();
          }}
        >
          <img
            src={`${assetHostname}/img/icon/${travelMode.toLowerCase()}.svg`}
            alt={`${travelMode} icon`}
            width="30"
            height="30"
          />
        </button>
      </>
    );
  }

  // Actually run application
  geolocateUser();

  // checking for searchParams
  const storeCodeQuery = searchParams.get("code");
  directions.destination =
    stores.find((store) => store.storeCode === storeCodeQuery) ?? null;

  if (directions.destination) initDirections();

  // END OF MAIN();
}

async function geolocateUser() {
  if (getExpirable(USER_POSITION_CONSENT)) {
    positions.user = await fetchUserPosition(positions.default);

    switch (positions.user.type) {
      case "html5":
        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    positions.current = positions.user;
    let center = positionToLatLngLiteral(positions.current);
    map.setCenter(center);
    map.setZoom(8);
    directions.origin = positions.user;
    userMarker.setPosition(center);
  } else {
    showConsentModal(true);
  }
}

// ---------- Miscellaneous functions ----------

function createInfoWindow(store: Store) {
  return (
    <article class="rt-iw">
      {store.status === "will_open_soon" && (
        <span class="rt-iw__next-opening | rt-badge rt-badge--tertiary">
          {retailtune.translations.k_opening_soon}
        </span>
      )}
      <span class="rt-iw__name">{store.name}</span>
      <div class="rt-iw__info">
        <span class="rt-iw__address">{store.address1}</span>
        <span class="rt-iw__city">
          {store.city} - {store.province.code} {store.postalCode}
        </span>
        <ul class="rt-iw__contacts">
          {store.phone && (
            <li>
              <a
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `PhoneClickMap-${store.city} ${store.address1}-${deviceType}-${retailtune.language}`,
                    store.id
                  );
                }}
                href={`tel:${store.phone}`}
              >
                <img
                  src={`${assetHostname}/img/icon/phone.svg`}
                  alt="phone icon"
                  width="15"
                  height="9"
                />
                {store.phone}
              </a>
            </li>
          )}
          {store.whatsapp && (
            <li>
              <a
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `WhatsappClickMap-${store.city} ${store.address1}-${deviceType}-${retailtune.language}`,
                    store.id
                  );
                }}
                href={`https://api.whatsapp.com/send?phone=${store.whatsapp}`}
              >
                <img
                  src={`${assetHostname}/img/icon/whatsapp.svg`}
                  alt="whatsapp icon"
                  width="15"
                  height="9"
                />
                {retailtune.translations.k_store_chat_now}
              </a>
            </li>
          )}
        </ul>
      </div>
      <div class="rt-iw__cta">
        <a
          onclick={(e:Event) => {
            e.stopPropagation();
            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `DetailsClickMap-${store.city} ${store.address1}-${deviceType}-${retailtune.language}`,
              store.id
            );
          }}
          class="rt-iw__details | rt-btn rt-btn--primary"
          href={retailtune.urlPrefix + store.storeLink.path}
        >
          {retailtune.translations.k_info_and_promotions}
        </a>
        <button
          onclick={(e:Event) => {
            e.stopPropagation();
            clearDrivingDirections();
            directions = {
              destination: store,
              origin: positions.user,
              travelMode: google.maps.TravelMode.DRIVING,
            };
            initDirections();

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `DirectionsClickMap-${store.city} ${store.address1}-${deviceType}-${retailtune.language}`,
              store.id
            );
          }}
          class="rt-iw__directions | rt-btn rt-btn--secondary"
        >
          {retailtune.translations.k_bring_me_here}
        </button>
      </div>
    </article>
  );
}

function getStoreCards(mapBounds: google.maps.LatLngBounds) {
  const { language, translations } = retailtune;

  visibleStores = [];
  for (let store of stores) {
    if (store.status === "closed") continue;
    
    const storeBounds = { lat: store.latitude, lng: store.longitude };
    const getDistance = getRadialDistanceFn(positions.current.latitude, positions.current.longitude);
    if (mapBounds.contains(storeBounds)) {
      store.distance = getDistance(store.latitude, store.longitude);
      visibleStores.push(store);
    }
  }

  visibleStores.sort(sortStoresByPriority);

  const backToNearestStoreButt = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  if (visibleStores.length === 0) {
    backToNearestStoreButt.classList.add("rt-back-to-nearest-store--visible");
  } else {
    backToNearestStoreButt.classList.remove(
      "rt-back-to-nearest-store--visible"
    );
  }

  const storeCountContainer = document.getElementById("rt_store_count")!;
  storeCountContainer.innerHTML =
    visibleStores.length + " " + retailtune.translations.k_stores_found;

  const storeListContainer = document.getElementById("rt_store_list")!;
  storeListContainer.replaceChildren(
    <>
      {visibleStores.map((store) => (
        <li
          id={`rt_${store.id}`}
          onclick={(e:Event) => {
            e.stopPropagation();
            
            if (deviceType === "desktop") {
              infoWindow.setContent(createInfoWindow(store));
              infoWindow.open(map, storeIdToMarkerMap.get(store.id));
              map.setCenter({
                lat: store.latitude,
                lng: store.longitude,
              }),
                map.setZoom(8);
            }

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `StoreClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          <article class="rt-store-card">
            {store.status === "will_open_soon" && (
              <span class="rt-store-card__next-opening | rt-badge rt-badge--tertiary">
                {retailtune.translations.k_opening_soon}
              </span>
            )}
            <span class="rt-store-card__name">{store.name}</span>
            <div class="rt-store-card__info">
              <span class="rt-store-card__address">{`${store.address1} ${store.city} ${store.postalCode} ${store.country.name}`}</span>
              <ul class="rt-store-card__contacts">
                {store.phone && (
                  <li>
                    <a
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `PhoneClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                          store.id
                        );
                      }}
                      href={`tel:${store.phone}`}
                    >
                      <span>
                        <img
                          src={`${assetHostname}/img/icon/phone.svg`}
                          alt="phone icon"
                          width="9"
                          height="15"
                        />
                      </span>
                      {store.phone}
                    </a>
                  </li>
                )}
                {store.whatsapp && (
                  <li>
                    <a
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `WhatsappClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                          store.id
                        );
                      }}
                      href={`https://api.whatsapp.com/send?phone=${store.whatsapp}`}
                    >
                      <img
                        src={`${assetHostname}/img/icon/whatsapp.svg`}
                        alt="whatsapp icon"
                        width="9"
                        height="15"
                      />
                      {translations.k_store_chat_now}
                    </a>
                  </li>
                )}
              </ul>
              {createOpeningHoursText(language, store, translations)}
            </div>
            <div class="rt-store-card__cta">
              <a
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DetailsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
                class="rt-store-card__details | rt-btn rt-btn--primary"
                href={`${retailtune.urlPrefix}${store.storeLink.path}`}
              >
                {retailtune.translations.k_info_and_promotions}
              </a>
              <button
                class="rt-store-card__directions | rt-btn rt-btn--secondary"
                onclick={(e: Event) => {
                  e.stopPropagation();
                  directions = {
                    destination: store,
                    origin: positions.user,
                    travelMode: google.maps.TravelMode.DRIVING,
                  };
                  initDirections();

                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DirectionsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {retailtune.translations.k_bring_me_here}
              </button>
            </div>
          </article>
        </li>
      ))}
    </>
  );
  return visibleStores.length;
}

function initDirections() {
  const { destination } = directions;
  if (!destination) {
    return;
  }

  if (deviceType !== "desktop") {
    return window.open(destination.googleMapsLink, "_blank");
  }

  const directionsPaneContainer = document.getElementById(
    "rt_directions_pane_container"
  )!;
  directionsPaneContainer.classList.add("rt-directions-pane--visible");

  const storeListScroller = document.getElementById("rt_store_list_scroller")!;
  storeListScroller.classList.remove("rt-back-to-top--visible");

  const destinationAddressEl = document.getElementById(
    "rt_address_destination"
  )!;
  destinationAddressEl.textContent = destination.name;

  const instructionsLableEl = document.getElementById("rt_instructions_label")!;
  instructionsLableEl.textContent = destination.name;

  markerClusterer.clearMarkers();

  if (!destinationMarker) {
    destinationMarker = new google.maps.Marker({
      position: { lat: destination.latitude, lng: destination.longitude },
      icon: `${assetHostname}/img/pin/pin-store.svg`,
      map,
    });
  }

  infoWindow.setContent(createInfoWindow(destination));
  infoWindow.open(map, destinationMarker);

  navigateUser();
}

async function navigateUser() {
  const { destination, travelMode } = directions;

  if (!destination) {
    return;
  }

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  for (let el of travelModesContainer.children) {
    el.getAttribute("travel-mode") === directions.travelMode.toLowerCase()
      ? el.classList.add("rt-travel-mode--selected")
      : el.classList.remove("rt-travel-mode--selected");
  }

  try {
    directionsResult = await directionsService.route({
      destination: { lat: destination.latitude, lng: destination.longitude },
      origin: positionToLatLngLiteral(directions.origin),
      travelMode: travelMode,
    });

    userMarker.setPosition(positionToLatLngLiteral(directions.origin));

    if (directionsRenderer) {
      directionsRenderer.setDirections(directionsResult);
    }

    if (directionsRenderer !== undefined) {
      const { steps } = directionsResult.routes[0].legs[0];

      const directionInstructionsList = document.getElementById(
        "rt_instructions_list"
      )!;
      directionInstructionsList.replaceChildren(
        <>
          {steps.map((step, index) => (
            <li class="rt-instructions-item">
              <div>
                <strong>{++index}.</strong>
                <span innerHTML={step.instructions}></span>
              </div>
            </li>
          ))}
        </>
      );
    }
  } catch (error) {
    toast(retailtune.translations.k_navigation_toast_message);
    console.warn(error);
  }
}

function clearDrivingDirections() {
  const directionsPaneContainer = document.getElementById(
    "rt_directions_pane_container"
  )!;
  directionsPaneContainer.classList.remove("rt-directions-pane--visible");

  const directionsPaneScroller = document.getElementById(
    "rt_directions_pane_scroller"
  )!;
  directionsPaneScroller.classList.remove("rt-back-to-top--visible");

  directions.destination = null;
  directions.travelMode = google.maps.TravelMode.DRIVING;
  directions.origin = positions.user;

  markerClusterer.addMarkers(Array.from(storeIdToMarkerMap.values()));
  userMarker.setPosition(positionToLatLngLiteral(positions.user));
  directionsRenderer.set("directions", null);

  if (destinationMarker) {
    destinationMarker.setMap(null);
    destinationMarker = null;
  }

  clearAutocomplete();
}

function showConsentModal(shouldShow: boolean) {
  const positionConsentModalContainer = document.getElementById(
    "rt_position_consent_modal"
  )! as HTMLDialogElement;
  if (shouldShow) {
    positionConsentModalContainer.showModal();
  } else {
    positionConsentModalContainer.close();
  }
}

// async function fetchData() {
//   const response = await fetch(
//     `https://test.api.retailtune.com/storelocator/${retailtune.language}/?rt_api_key=${retailtune.api_key}`
//   );
//   if (!response.ok) {
//     throw new Error(response.statusText);
//   }
//   return response.json();
// }

let i = setInterval(() => {
  try {
    if (!google || !retailtune || !sendGa4DL)
      throw new Error("Inexisting required objects to load this page!");
    clearInterval(i);
    main();
  } catch (error) {
    console.warn(error);
  }
}, 50);
